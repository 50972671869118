import React, { createContext, useContext, useState, useEffect } from 'react';
import GraphQLContext from './GraphQLProvider';
import { bcApi } from '../helpers/bigcommerce'
import { registerAccount, initLogin, validateLogin, confirmPassword } from '../helpers/auth'
import { getStorage, removeStorage, setStorage, infoStorage } from '../helpers/general'

const AuthContext = createContext();

const initialState = {
    userChecked: false,
    isLoggedIn: false,
    customerId: 0,
    email: '',
    support: {},
  };

export const AuthProvider = ({ children, bcPath, clientId }) => {
  const graphqlCxt = useContext(GraphQLContext);
  const graphqlQuery = graphqlCxt && graphqlCxt.query
  const [state, setState] = useState(initialState);

  const addSupport = (key, value) => {
    const support = state.support;
    if (!(key in support)) {
      support[key] = value;
      setState({...state, support});
    }
  }

  const checkLoggedIn = () => {
    const sessionData = infoStorage('_loggedIn');
    if (sessionData) {
      validateLogin(sessionData).then(response => {
        if (String(response.status).startsWith('2') && response.response.token) {
          setStorage('_loggedIn', response.response.token, sessionData.storage === 'sessionStorage' ? true : false);

          setState({
            ...state,
            ...response.response.object
          })
          // resolve(response)
        } else {
          clearStorage();setState({...state, userChecked: true, onFetchLogin: false})
        }
      }).catch(error => {
        console.log(error);
        clearStorage();setState({...state, userChecked: true, onFetchLogin: false})
      })
    } else {
      clearStorage();setState({...state, userChecked: true, onFetchLogin: false})
    }
  }

  // eslint-disable-next-line
  useEffect(() => checkLoggedIn(), []);

  const refreshData = () => {
    if (state.isLoggedIn) {
      // fetchLogin(state.customerId);
      checkLoggedIn();
    }
  }

  const afterAuth = async (customerId) => {
    const afterAuthCalls = getStorage('_afterAuth');
    let actionResult = false;

    if (afterAuthCalls) {
      const afterAuthCallsObject = JSON.parse(afterAuthCalls);
      if (afterAuthCallsObject.action === 'saveWishlist') {
        // console.log("_afterAuth should be removed");
        removeStorage('_afterAuth')
        actionResult = await state.support.wishlist.saveWishlist(afterAuthCallsObject.name, true, customerId)
      }
    }

    if (typeof window !== 'undefined' && typeof document !== 'undefined' && !actionResult) {
      // console.log("Redirecting normally");
      const browserLastPage = !document.referrer.startsWith(process.env.SITE_URL) ? process.env.SITE_URL : (document.referrer.indexOf('logout') ? process.env.SITE_URL : document.referrer);
      const userLastPage = getStorage('lastPage');
      const forcedPage = getStorage('forcedPage');
      const returnUrl = forcedPage || userLastPage || browserLastPage;
      removeStorage('forcedPage');
      window.location = returnUrl;
    }

    return actionResult
  }

  const logout = () => {
    setState(initialState)
    clearStorage(true);

    bcApi('carts').then(async ({response}) => {
      if (response && 'data' in response && 'id' in response.data) {
        await bcApi(`carts/${response.data.id}`, 'PUT', {'customer_id': 0});
      }
      graphqlQuery(`
        mutation Logout {
          logout {
            result
          }
        }
      `).then(response => {
        if (typeof window !== 'undefined') {
          window.location = '/';
        }
      });
    });
  }

  const clearStorage = (fullClear) => {
    removeStorage('_loggedIn')
    removeStorage('_isWholesale')
    removeStorage('_isPending')
    if (fullClear) {
      removeStorage('__jammcd');
    }
  }

  const checkPassword = (email, password) => {
    return new Promise((res, rej) => {
      confirmPassword(email, password).then(response => {
        res(response);
      }).catch(e => {
        rej(e);
      })
    })
  }

  const login = (email, password, remember) => {
    return new Promise((res, rej) => {
      initLogin(email, password).then(async response => {
        // console.log("initLogin response", response);
        if (String(response.status).startsWith('2') && response.response.token) {
          setStorage('_loggedIn', response.response.token, remember ? false : true);

          setState({
            ...state,
            ...response.response.object
          })
          
          await afterAuth(response.response.object.customerId);

          res(response)
        } else {
          rej(response);
        }
      }).catch(e => rej(e));
    });
  }

  /* TODO: To be ported into the auth file */
  const forgotPassword = (email) => {
    return new Promise((res) => {
      fetch(`${bcPath}/login.php?action=send_password_email`, {
        body: `email=${email}`,
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "post",
      }).then(result => {
        // console.log(result);
        res(true)
      }).catch(error => {
        // Endpoint returns failed however the process is actually successful. As such, we can't effectively
        // console.log(error);
        res(true)
      });
    });
  }

  const changePassword = (fields, customerId, token) => {
    const bcApiBody = JSON.stringify([fields]);
    fetch(`${bcPath}/login.php?action=save_new_password&c=${customerId}&t=${token}`, {
      body: bcApiBody,
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "post",
    }).then(result => {
      console.log(result);
    });
  }

  const signup = (usuFields) => {
    return new Promise((res, rej) => {
      registerAccount(usuFields).then(async response => {
        if (String(response.status).startsWith('2')) {
          if (!usuFields.cart && response.response.token) {
            // Log user in as its a free account
            setStorage('_loggedIn', response.response.token);

            setState({
              ...state,
              ...response.response.object
            })
            
            await afterAuth(response.response.object.customerId);

            res(response);
          } else {
            res(response);
          }
        } else {
          rej(response);
        }
      }).catch(e => rej(e));
    });
  }

  return (
    <AuthContext.Provider value={{
        state,
        setState,
        addSupport,
        refreshData,
        login,
        logout,
        signup,
        checkPassword,
        forgotPassword,
        changePassword
    }}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;