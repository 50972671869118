import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

const ContentfulContext = createContext();

export const ContentfulProvider = ({ children }) => {
    const [headerMenu, setHeaderMenu] = useState([]);
    const [faqPage, setFaqPage] = useState([]);
    const [faqsCategories, setFaqsCategories] = useState([]);
    const [footerMenu, setFooterMenu] = useState([]);
    const [secondaryFooterMenu, setSecondaryFooterMenu] = useState([]);
    const [socialLinks, setSocialLinks] = useState({});
    const [socialLinksBlog, setSocialLinksBlog] = useState({});
    // const [seoData, setSeoData] = useState({});
    
    const contentfulData = useStaticQuery(graphql`
        query {
            faqPage: contentfulPage(pageName: {eq: "FAQs"}) {
                pageName
                pageTitle
                metaCanonicalLink
                metaDescription
                metaTitle
                metaPreviewImage{
                  file {
                    url
                  }
                }
            }
            faqsCategories: allContentfulFaqCategories {
                edges {
                    node {
                      slug
                      name
                      order
                      categoryIcon {
												file {
                          url
                          fileName
                          contentType
                        }
                      }
                      faqItems {
                        name
                        content {
                          raw
                          references {
                            ... on ContentfulAsset {
                              contentful_id
                              title
                              file {
                                url
                                fileName
                                details {
                                  size
                                }
                              }
                              id
                            }
                          }
                        }
                        updatedAt(formatString: "MMMM DD, YYYY")
                      }
                      categoryIconHover {
												file {
                          url
                          fileName
                          contentType
                        }
                      }
                    }
                }
            }
            siteConfig: allContentfulSiteConfig(filter: {name: {eq: "Site Config"}}) {
                nodes {
                  headerMenu {
                    menuItems {
                      title
                      url
                      linkTarget
                      linkLocation
                      contents {
                        name
                        url
                        linkTarget
                        linkLocation
                      }
                    }
                  }
                  footerMenu {
                    menuItems {
                      title
                      url
                      linkTarget
                      linkLocation
                    }
                  }
                  secondaryFooterMenu {
                    menuItems {
                      title
                      name
                      url
                      linkTarget
                      linkLocation

                    }
                  }
                  tikTokLink
                  instagramLink
                  youtubeLink
                  facebookLink
                  twitterLink
                  linkedInLink
                }
            }
        }
    `);
    // console.log(contentfulData);
    const fetchData = useCallback(() => {
        if (contentfulData && contentfulData.siteConfig.nodes.length > 0) {
            setFaqPage(get(contentfulData, 'faqPage', ''));
            setFaqsCategories(get(contentfulData, 'faqsCategories', ''));
            setHeaderMenu(get(contentfulData, 'siteConfig.nodes[0].headerMenu.menuItems', []));
            setFooterMenu(get(contentfulData, 'siteConfig.nodes[0].footerMenu', []));
            setSecondaryFooterMenu(get(contentfulData, 'siteConfig.nodes[0].secondaryFooterMenu.menuItems', []));
            setSocialLinks({
                facebook: get(contentfulData, 'siteConfig.nodes[0].facebookLink', ''),
                instagram: get(contentfulData, 'siteConfig.nodes[0].instagramLink', ''),
                twitter: get(contentfulData, 'siteConfig.nodes[0].twitterLink', ''),
                linkedIn: get(contentfulData, 'siteConfig.nodes[0].linkedInLink', ''),
                youtube: get(contentfulData, 'siteConfig.nodes[0].youtubeLink', ''),
                // tikTok: get(contentfulData, 'siteConfig.nodes[0].tikTokLink', ''),
            });
            setSocialLinksBlog({
                facebook: get(contentfulData, 'siteConfig.nodes[0].facebookLink', ''),
                twitter: get(contentfulData, 'siteConfig.nodes[0].twitterLink', ''),
                linkedIn: get(contentfulData, 'siteConfig.nodes[0].linkedInLink', ''),
            });
        }
    }, [contentfulData]);

    useEffect(() => fetchData(), [fetchData]);

    return (
        <ContentfulContext.Provider value={{
            faqPage,
            faqsCategories,
            headerMenu,
            footerMenu,
            secondaryFooterMenu,
            socialLinks,
            socialLinksBlog
        }}>{children}</ContentfulContext.Provider>
    );
};

export default ContentfulContext;