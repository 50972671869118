import React from 'react';

import { GraphQLProvider } from './src/context/GraphQLProvider';
import { AuthProvider } from './src/context/AuthProvider';
import { WishlistProvider } from './src/context/WishlistProvider';
import { PriceProvider } from './src/context/PriceProvider';
import { CartProvider } from './src/context/CartProvider';
import { BindProvider } from './src/context/BindProvider';
import { BlogListsProvider } from './src/context/BlogListsProvider';
import { ContentfulProvider } from './src/context/ContentfulProvider';
import { HelmetProvider } from 'react-helmet-async';

require('dotenv').config();
export const wrapRootElement = ({ element }) => (
  <HelmetProvider>
    <GraphQLProvider bcPath={process.env.BC_PATH} domain={process.env.SITE_URL}>
      {/* <AuthProvider bcPath={process.env.BC_PATH} clientId={process.env.BC_CLIENT_ID}>
        <WishlistProvider>
          <PriceProvider>
            <CartProvider> */}
      {/* <BindProvider> */}
      <BlogListsProvider>
        <ContentfulProvider>{element}</ContentfulProvider>
      </BlogListsProvider>
      {/* </BindProvider> */}
      {/* </CartProvider>
          </PriceProvider>
        </WishlistProvider>
      </AuthProvider> */}
    </GraphQLProvider>
  </HelmetProvider>
);

