import { useState, useEffect } from 'react';
import { scroller } from "react-scroll";
import Player from '@vimeo/player';

/**
 * Track recently viewed products
 * 
 * Handles tracking of product views and stores product IDs in session for display on the site
 * 
 * @param   {Number} productId       The prodoct ID to track
 * 
    import { trackRV } from '../helpers/general'

    trackRV(productId).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
function trackRV(productId) {
  const sessionKey = '__trackRV';
  const displayAmount = 10;

  if (typeof sessionStorage !== 'undefined') {
    const recentlyViewed = getStorage(sessionKey);
    let recentlyViewedArr = [];

    if (recentlyViewed !== null) {
      recentlyViewedArr = JSON.parse(recentlyViewed);
    }

    const existIndex = recentlyViewedArr.indexOf(productId);

    if (existIndex > -1) {
      recentlyViewedArr.splice(existIndex, 1);
    }

    recentlyViewedArr.unshift(productId);

    if (recentlyViewedArr.length > displayAmount) {
      recentlyViewedArr.pop();
    }

    setStorage(sessionKey, JSON.stringify(recentlyViewedArr), true);
  }
}

/**
 * Get local / session / cookie storage information
 * 
 * Handles retrieving browser storage information functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { infoStorage } from '../helpers/general'

    infoStorage('theKey')
 */
function infoStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return {storage: 'sessionStorage', value: sessionValue};
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return {storage: 'localStorage', value: localValue};
      }
    }
  } catch (e) {
    infoCookie(key);
  }

  return null;
}

/**
 * Get cookie storage information
 * 
 * Handles browser storage information functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { infoCookie } from '../helpers/general'

    infoCookie('theKey')
 */
function infoCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return {storage: 'cookie', value: c.substring(name.length, c.length)};
      }
    }
    return null;
  }
}

/**
 * Get local / session / cookie storage
 * 
 * Handles retrieving browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { getStorage } from '../helpers/general'

    getStorage('theKey')
 */
function getStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return sessionValue;
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return localValue;
      }
    }
  } catch (e) {
    getCookie(key);
  }

  return null;
}

/**
 * Get cookie storage
 * 
 * Handles browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { getCookie } from '../helpers/general'

    getCookie('theKey')
 */
function getCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
}

/**
 * Set local / session / cookie storage
 * 
 * Handles setting browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 * 
    import { setStorage } from '../helpers/general'

    setStorage('theKey', 'theValue', true)
 */
function setStorage(key, value, expire) {
  try {
    // Use Local / Session storage
    if (typeof sessionStorage !== 'undefined' && expire) {
      sessionStorage.setItem(key, value);
    } else if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, value);
    }
  } catch (e) {
    // Use Cookies
    setCookie(key, value, expire);
  }
}

/**
 * Set cookie storage
 * 
 * Handles setting browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 * 
    import { setCookie } from '../helpers/general'

    setCookie('theKey', 'theValue', true)
 */
function setCookie(key, value, expire) {
  if (typeof document !== 'undefined') {
    let exDays = 365;
    if (expire) {
      exDays = 1;
    }
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=${value};${expires};path=/`;
  }
}

/**
 * Remove local / session / cookie storage
 * 
 * Handles removing browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { removeStorage } from '../helpers/general'

    removeStorage('theKey')
 */
function removeStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        sessionStorage.removeItem(key);
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        localStorage.removeItem(key);
      }
    }
  } catch (e) {
    // Use Cookies
    removeCookie(key);
  }
}

/**
 * Remove cookie storage
 * 
 * Handles removing browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { removeCookie } from '../helpers/general'

    removeCookie('theKey')
 */
function removeCookie(key) {
  if (typeof document !== 'undefined') {
    const d = new Date();
    d.setTime(d.getTime() - 1 * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=;${expires};path=/`;
  }
}

/**
 * Validate email format
 * 
 * Checks the provided email address and validates its format
 * 
 * @param   {String} email  The email address
 * 
    import { validateEmail } from '../helpers/general'

    validateEmail(email)
 */
function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * Validate strong password format
 * 
 * 
 * @param   {String} password  The password
 * 
    import { validateStrongPassword } from '../helpers/general'

    validateStrongPassword(email)
 */
function validateStrongPassword(password) {
  return /(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password);
}

/**
 * Validate phone format
 * 
 * Checks the provided phone number and validates its format
 * 
 * @param   {String} number  The phone number
 * 
    import { validatePhoneNumber } from '../helpers/general'

    validatePhoneNumber(number)
 */
function validatePhoneNumber(number) {
  const regex = /^[- +()]*[0-9][- +()0-9]*$/;

  return regex.test(number);
}

/**
 * Is value numeric
 * 
 * Determine whether variable is a number
 * 
 * @param {*} str 
 *
    import { isNumeric } from '../helpers/general'

    isNumeric(value)
 */
function isNumeric(str) {
  if (['string', 'number'].indexOf(typeof str) === -1) return false // we only process strings and numbers! 
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

/**
 * Is value an object
 * 
 * Determine whether variable is an object
 * 
 * @param {*} obj 
 *
    import { isObject } from '../helpers/general'

    isObject(obj)
 */
function isObject(obj) {
  return (Array.isArray(obj) === false && typeof obj === 'object')
}

/**
 * Format price
 * 
 * Format number into local currency output
 * 
 * @param {Number} amount 
 * @param {String} currency
 * @param {Boolean} appendZero
 *
    import { formatPrice } from '../helpers/general'

    formatPrice(value, "USD", true)
  */
function formatPrice(amount, currency = "AUD", appendZero = false) {
  let displayAmount = (typeof amount !== 'number' && parseFloat(amount?.replace('$', ''))) || amount;
  /* Set language display */
  const languageCode =
    typeof window !== 'undefined'
      ? window.navigator.language || 'en-AU'
      : 'en-AU';

  /* Format and return */
  // isolate currency
  const formatObject = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency
  });
  let formattedPrice = formatObject.format(displayAmount);
  if ('formatToParts' in formatObject) {
    const formattedPriceParts = formatObject.formatToParts(displayAmount);
    const currencyValue = formattedPriceParts.find(obj => obj.type === 'currency');
    const decimalValue = formattedPriceParts.find(obj => obj.type === 'fraction');
    formattedPrice = formattedPrice.replace(currencyValue.value, '');
    if (decimalValue && decimalValue.value === '00' && !appendZero) {
      formattedPrice = formattedPrice.replace(`.${decimalValue.value}`, '');
    }
  }
  
  return formattedPrice;
}

/**
 * Get ordinal for date number
 * 
 * Given a date number, this will return the suffix for a date
 * 
 * @param   {Number} n  The date
 * 
    import { dateOrdinal } from '../helpers/general'

    dateOrdinal(25)
 */
function dateOrdinal(n) {
  return n > 3 && n < 21
    ? 'th'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 3
    ? 'rd'
    : 'th';
}

/**
 * Get value from querystring
 *
 * @param {string} key The key of the value you're looking to retrieve
 * @returns {string|false} the value or false if it doesn't exist
 */
 function getQuerystringValue(key) {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);

  return value || false;
}

/**
 * Get slug of the product from given uri
 *
 * @param {string} uri the url of the product
 * example: http://your-store-url.mybigcommerce.com/your-product/
 * @returns {string} slug of the product
 * example: /product/your-product/
 */
function getUriFromURL(urlStr = '') {
  const slug = urlStr
    .split('/')
    .filter(x => x)
    .pop();
  if (slug) {
    return `/product/${slug}/`;
  }
  return '';
}

/**
 * Calculate read time
 *
 * Pass content to be counted and return a minute value for the read time length
 *
 * @param   {string} content The content to be counted
 *
 * import { readTime } from '../helpers/general'
 *
 * readTime(content)
 */
function readTime(content) {
  const foundImages = (content.match(/<img/g) || []).length;
  const text = content.replace(/(<([^>]+)>)/gi, '');
  const foundWords = text.split(' ').length;

  let secs = (foundWords / 275) * 60;
  let addSecs = 12;
  for (let i = 0; i < foundImages; i++) {
    secs += addSecs;
    addSecs--;
    if (addSecs < 3) addSecs = 3;
  }
  const minutes = Math.ceil(secs / 60);

  return minutes;
}

/**
 * Fetch title
 * 
 * Determine which title for a product to use
 * 
 * @param   {string} bcData   The BC product title
 * @param   {string} otherData   The string to split by
 * 
 * import { fetchTitle } from '../helpers/general'
 * 
 * fetchTitle(bcData, otherData)
 * 
 * @returns {string} title
 */
 function fetchTitle(bcData, otherData) {
  let title = '';
  /* Add logic here if there are other sources of the product name */
  if (otherData.name) {
    title = otherData.name;
  } else if (bcData.name !== undefined) {
    const splitTitle = dissectTitle(bcData.name, ' (');
    title = splitTitle[0];
  }

  return title;
}

/**
 * Dissect title
 * 
 * Split out title to reveal the design type from the product name
 * 
 * @param   {string} productTitle   The product title
 * @param   {string} splitBy        The string to split by
 * 
 * import { dissectTitle } from '../helpers/general'
 * 
 * dissectTitle(productTitle, splitBy)
 * 
 * @returns {array} splitTitle
 */
function dissectTitle(productTitle, splitBy) {
  let titleParts = [''];
  if (productTitle !== undefined) {
     titleParts = productTitle.split(splitBy);
  }
  
  return titleParts;
}

/**
 * Capitalise first letter
 * 
 * Capitalise the first letter in the provided string
 * 
 * @param   {string} string   The string
 * 
 * import { ucFirst } from '../helpers/general'
 * 
 * ucFirst(string)
 * 
 * @returns {string} the capitalised string
 */
function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Window Dimensions
 *
 * Gets values from window object for the `useWindowDimensions` function
 *
 */
function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  return { width: 0, height: 0 };
}

/**
 * Window Dimensions
 * 
 * Provide on the fly window dimensions
 * 
    import { useWindowDimensions } from '../helpers/general'

    const { width } = useWindowDimensions()
 */
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }

    return;
  }, []);

  return windowDimensions;
}

/**
 * useScript
 *
 * Provides a way to include a script on the frontend
 * 
    import { useScript } from '../helpers/general'

    useScript('https://path.to/script', 'functionOrObject', functionOnceLoaded);
 */
function useScript(url, functionOrObject, callback) {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const existing = functionOrObject in window;
      const loading = window.scriptsLoading && window.scriptsLoading.indexOf(functionOrObject) > -1;
      if (!existing && !loading) {
        if (!('scriptsLoading' in window)) {
          window.scriptsLoading = [];
        }
        window.scriptsLoading.push(functionOrObject);
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => { 
          if (callback) {
            callback();
          }
        };

        return () => {
          document.body.removeChild(script);
        }
      }

      if (existing && callback) {
        callback();
      }
    }
  }, [url, functionOrObject, callback])
}

/**
 * Decode unicode characters
 * 
 * Provides a way to decode a string containing unicode characters for logic purposes
 * 
    import { decodeUnicode } from '../helpers/general'

    decodeUnicode(content);
 */
function decodeUnicode(text) {
  var map = {
      '&amp;': '&',
      '&#038;': "&",
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#039;': "'",
      '&#8217;': "'",
      '&#8216;': "'",
      '&#8211;': "-",
      '&#8212;': "-",
      '&#8230;': "…",
      '&#8221;': '"',
      '&#8243;': '"'
  };
  // console.log("Decoding", text);
  return text.replace(/&[\w\d#]{2,5};/g, function(m) { return map[m]; });
}

function rewriteShippingOptionLabel(label, hideType = false) {
  let modLabel = label;
  /* Write logic to rename shipping option labels from BC */
  return modLabel;
}


/**
 * relocate
 * 
 * An agnostic function that is configured to support the framework being used, with a fallback
 * 
    import { relocate } from '../helpers/general'

    relocate('/path');
 */
function relocate(path) {
  /**
   * GATSBY Routing
   */
  // if (process.env.GATSBY_ENABLED && !navigate) {
  //   console.error("** MODIFY helpers/general to enable Gatsby's Navigate function **");
  //   return;
  // }
  // if (navigate) {
  //   navigate(path);
  //   return;
  // }

  /**
   * NEXTJS Routing
   * This is an invalid way of utilising useRouter in NextJS. Look to use useRouter directly in the page using this function.
   * 
     import { useRouter } from 'next/router';
      const relocate = useRouter();
      relocate.push(path)
    */
  // if (process.env.NEXT_PIBLIC_ENABLED && !useRouter) {
  //   console.error("** MODIFY helpers/general to enable NextJS's useRouter function **");
  //   return;
  // }
  // if (useRouter) {
  //   const router = useRouter();
  //   router.push(path);
  //   return;
  // }

  console.warn("Standard window.location functionality used to route the user. Consider enabling the frameworks method.");
  window.location = path;
  return;
}

/**
 * dataLayerPush
 *
 * Enables a data push connection to a connected GTM container
 * 
    import { dataLayerPush } from '../helpers/general'

    dataLayerPush(eventName, {extraVariable: 'value'}, productData, linkToNavigate);

    Predefined event names:
      - SearchResults: sends list of products listed in the search result
        eg: dataLayerPush('SearchResults', null, productObject);

      - AllProductsOnPage: sends list of products listed on the current page (PLP, PDP, etc)
        eg: dataLayerPush('AllProducts On Page', null, productObject);

      - AddedToWishlist: sends product that was added to the wishlist
        eg: dataLayerPush('AddedToWishlist', null, productObject);

      - ProductClick: sets up enhanced ecomm tracking when a product is clicked to be viewed
        eg: dataLayerPush('ProductClick', null, productObject, path);

      - ProductView: sets up enhanced ecomm tracking when viewing a product page
        eg: dataLayerPush('ProductView', null, productObject);

      - AddedToCart: sets up enhanced ecomm tracking when adding a product (or products) to cart
        eg: dataLayerPush('AddedToCart', null, productObject);

      - RemovedFromCart: sets up enhanced ecomm tracking when removing a product (or products) to cart
        eg: dataLayerPush('RemovedFromCart', null, productObject);

      - Checkout: sends checkout data to track prior to payment
        eg: dataLayerPush('Checkout', null, checkoutObject, '/checkout/');
  */
function dataLayerPush(eventName, extraData, productData, linkTo) {
  // Ensure window is available before moving forward
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];

    // console.log('dataLayer Data', extraData, productData, linkTo);

    const object = {
      ...extraData,
      event: eventName
    }

    let productsObject = false;

    const productObject = product => ({
      id: product.entityId || product.entity_id || product.productId || product.product_id || product.id || 0,
      name: product.title || product.name || '',
      price: product.price || 0,
      currency: 'AUD',
      // category: product.categories,
      quantity: product.quantity || 1,
    });

    if (productData) {
      const products = !Array.isArray(productData) ? [productData] : productData;
      productsObject = [...products.map(product => productObject(product))]
    }

    switch (eventName) {
      case 'SearchResults': 
      case 'AllProductsOnPage': 
        if (productsObject) {
          object.products = productsObject;
        }
        break;

      case 'ProductClick':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            click: {
              products: productsObject
            }
          }
        }
        break;

      case 'ProductView':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            detail: {
              products: productsObject
            }
          }
        }
        break;

      case 'AddedToCart':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            currencyCode: 'AUD',
            add: { products: productsObject }
          }
        }
        break;

      case 'RemovedFromCart':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            remove: {
              products: productsObject
            }
          }
        }
        break;

      case 'Checkout':
        if (productsObject) {
          object.checkout = {
            totalAmount: productsObject.grandTotal,
            tax: productsObject.taxTotal,
            shipping: productsObject.shippingCostTotal,
            products: []
          };

          const prodTypes = Object.keys(productsObject.cart.lineItems);
          prodTypes.map((type) => {
            if (productsObject.cart.lineItems[type].length > 0) {
              productsObject.cart.lineItems[type].map((item) => {
                const itemId = `${item.productId}_${item.variantId}`;
                const itemName = `${item.name} (${item.sku})`;
                const product = productObject({id: itemId, title: itemName, price: item.salePrice, quantity: item.quantity});
                object.checkout.products.push(product);
                return true;
              });
            }
            return true;
          });
        }
        break;

      default:
        // do nothing
        break;
    }

    // Check if GTM is loaded
    if (linkTo) {
      if (typeof window.google_tag_manager !== 'undefined') {
        object.eventCallback = () => {
          if (linkTo.startsWith('http')) {
            document.location = linkTo;
          } else {
            relocate(linkTo)
          }
        };
      } else {
          // Handler for when GTM is not present
          if (linkTo.startsWith('http')) {
            document.location = linkTo;
          } else {
            relocate(linkTo)
          }
      }
    }

    // console.log('Pushing DataLayer: ', object);
    window.dataLayer.push(object);
  }
}

// Custom Functions
function radioClass(e) {
    const elemClassName = e.target.getAttribute('data-btnName');
    const elems = document.getElementsByClassName(elemClassName);
    for (let i = 0; i < elems.length; i++) {
        elems[i].classList.remove('active');
    }
    e.target.classList.add('active')
};

function scrollToSection(e) {
    const elemDeeplink = e.target.getAttribute('data-deeplink');
    scroller.scrollTo(elemDeeplink, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
};

function checkScrollDirection(event) {
  if (checkScrollDirectionIsUp(event)) {
    document.body.classList.remove('down');
    document.body.classList.add('up');
  } else {
    document.body.classList.add('down');
    document.body.classList.remove('up');
  }
}
function checkScrollDirectionIsUp(event) {
  if (event.wheelDelta) {
    return event.wheelDelta > 0;
  }
  return event.deltaY < 0;
}

function scrollAnimation(e, sectionId, sectionIndex) {
    let scrollableElement = document.body;
    scrollableElement.addEventListener('wheel', checkScrollDirection);
    const windowDimensions = getWindowDimensions();
    const contentfulPage = document.getElementsByClassName('contentful-page')[0];
    const genElemID = `${sectionId}-${sectionIndex}`;
    const elem = document.getElementById(genElemID) ? document.getElementById(genElemID) : '';
    if (!!elem) {
      const elemHeight = parseInt(elem.offsetHeight);

      const ContentfulBlockType = elem ? elem.getAttribute('data-block') : '';
      const elemAnimation = elem.querySelector(`.ContentfulBlockTiles`)
        ? elem.querySelector(`.ContentfulBlockTiles`).getAttribute('data-animation')
        : elem.querySelector(`.ContentfulBlockAniParallaxScrolling`)
          ? elem.querySelector(`.ContentfulBlockAniParallaxScrolling`).getAttribute('data-animation')
          : '';
      const elemStyle = elem.querySelector(`.ContentfulBlockTiles`)
        ? elem.querySelector(`.ContentfulBlockTiles`).getAttribute('data-style')
        : elem.querySelector(`.ContentfulBlockAniParallaxScrolling`)
          ? elem.querySelector(`.ContentfulBlockAniParallaxScrolling`).getAttribute('data-style')
          : '';

      if (ContentfulBlockType === 'ContentfulBlockAniParallaxScrolling' || ContentfulBlockType === 'ContentfulBlockTiles') {
        const elemTarget = document.getElementById(`target-${genElemID}`) ? document.getElementById(`target-${genElemID}`) : '';
        const elemTargetContent = document.getElementById(`bx-${genElemID}`) ? document.getElementById(`bx-${genElemID}`) : '';
        const elemTargetPos = parseInt(window.scrollY + document.getElementById(genElemID).getBoundingClientRect().top - 50);
        const genPos = window.scrollY - elemTargetPos;
        const scrollPos = Math.abs(elemTargetPos + elemHeight);
        console.log(scrollPos, elemTargetPos)

        let setOffset = 200;
        let activeIndicator = -1;
        const dataIndicators = elem.querySelectorAll(`[data-indicator]`);
        const dataItems = elem.querySelectorAll(`[data-item]`);
        if (elemAnimation === 'parallax scrolling') {
          const checkPassPos = Math.abs(parseInt(scrollPos - 800));
          const genOpacity = (1 - ((Math.abs(window.scrollY - elemTargetPos) + setOffset) / elemHeight)).toFixed(1);

          if (elemStyle === 'content-left indicators-left' || elemStyle === 'content-right indicators-right') {
            if (windowDimensions['width'] > 800) {
              if (window.scrollY > elemTargetPos && window.scrollY < scrollPos) {
                if (contentfulPage.classList.contains('activated') === false) {
                  window.scrollTo({top: elemTargetPos - 1, behavior: 'auto'});
                  window.scroll({behavior: 'smooth'});
                  const contentfulHeight = contentfulPage.offsetHeight;
                  document.body.style.height = `${contentfulHeight + 1000}px`;
                  document.body.classList.add('hide-scrolling');
                  contentfulPage.style.position = 'fixed';
                  contentfulPage.style.top = `-${elemTargetPos}px`;
                  elem.setAttribute('data-pos', elemTargetPos);
                  contentfulPage.classList.add('activated');
                  dataIndicators[0].classList.add('active');
                  dataItems[0].classList.add('active');
                  const backgroundContainer = document.getElementById('bx-bg-color');

                  if (backgroundContainer) {
                    backgroundContainer.style.backgroundColor = `#${dataItems[0].dataset?.backgroundColor}`;
                  }
                }
              } else {
                if (elem.classList.contains('active')) {
                  const windowScrollDetect = 500;
                  let isScrolling;
                  window.addEventListener('scroll', (e) => {
                    window.clearTimeout(isScrolling);
                    isScrolling = setTimeout(() => {
                      if (window.scrollY > windowScrollDetect) {
                        const lastIndicator = dataIndicators.length - 1;
                        if (activeIndicator < lastIndicator) {
                          for (let i = 0; i < dataIndicators.length; i++) {
                            if (dataItems[i].classList.contains('active')) {
                              if (i < lastIndicator && activeIndicator < lastIndicator) {
                                dataItems[i].classList.remove('active');
                              }
                            }
                            if (dataIndicators[i].classList.contains('active')) {
                              if (i < lastIndicator && activeIndicator < lastIndicator) {
                                dataIndicators[i].classList.remove('active');
                              }
                              activeIndicator = i < (dataIndicators.length - 1) ? i + 1 : dataIndicators.length;
                              break;
                            }
                          }

                          if (contentfulPage.classList.contains('execute') === false) {
                            if ((activeIndicator < dataIndicators.length)) {
                              window.scrollTo({top: 0, behavior: 'auto'});
                              window.scroll({behavior: 'smooth'});
                              activeIndicator = activeIndicator === -1 ? 0 : activeIndicator;
                              dataIndicators[activeIndicator].classList.add('active');
                              dataItems[activeIndicator].classList.add('active');
                              const backgroundContainer = document.getElementById('bx-bg-color');

                              if (backgroundContainer) {
                                backgroundContainer.style.backgroundColor = `#${dataItems[activeIndicator].dataset?.backgroundColor}`;
                              }
                              window.scroll({behavior: 'auto'});
                            } else {
                              dataIndicators[(dataIndicators.length - 1)].classList.add('active');
                              dataItems[(dataIndicators.length - 1)].classList.add('active');
                              const getElemPos = elem.getAttribute('data-pos');
                              document.body.style.height = 'auto';
                              contentfulPage.style.position = 'relative';
                              contentfulPage.style.top = `0px`;
                              window.scrollTo({top: getElemPos, behavior: 'auto'});
                              window.scrollTo({top: (parseInt(getElemPos) + 600), behavior: 'smooth'});
                              contentfulPage.classList.add('execute');
                              document.body.classList.remove('hide-scrolling');

                              e.stopImmediatePropagation();
                            }
                          }
                        }
                      }
                    }, 100);
                  }, false);
                }
              }
            } else {
              dataIndicators[0].classList.add('active');
              dataItems[0].classList.add('active');
            }

            const indicators = document.querySelectorAll('[data-indicator]')
            for (let k = 0; k < indicators.length; k++) {
              indicators[k].addEventListener("click", (e) => {
                const selectedDataIndicator = indicators[k].getAttribute('data-indicator');
                for (let i = 0; i < dataIndicators.length; i++) {
                  if (dataItems[i].classList.contains('active') && k !== i) {
                    dataItems[i].classList.remove('active');
                  }
                  if (dataIndicators[i].classList.contains('active') && k !== i) {
                    dataIndicators[i].classList.remove('active');
                  }
                }
                dataIndicators[selectedDataIndicator].classList.add('active');
                dataItems[selectedDataIndicator].classList.add('active');
                const backgroundContainer = document.getElementById('bx-bg-color');

                if (backgroundContainer) {
                  backgroundContainer.style.backgroundColor = `#${dataItems[selectedDataIndicator].dataset?.backgroundColor}`;
                }
                e.stopImmediatePropagation();
              });
            }
          } else {
            if (windowDimensions['width'] > 800) {
              if (window.scrollY > elemTargetPos && window.scrollY < scrollPos) {
                elem.style.top = `${genPos}px`;
              } else {
                elemTarget.style.top = '20%';
              }
              if (window.scrollY > checkPassPos) {
                elemTargetContent.style.opacity = genOpacity;
              } else {
                elemTargetContent.style.opacity = 1;
              }
              setOffset = -120;
            }
          }
        }
        if (windowDimensions['width'] > 800) {
          if (window.scrollY > (elemTargetPos - setOffset) && window.scrollY < scrollPos) {
            elem.classList.add('active');
            elem.classList.add('detected');
            elem.querySelector('[data-card]').classList.add('active');
            elem.querySelector('[data-card]').classList.add('detected');
          } else {
            elem.classList.remove('active');
            elem.querySelector('[data-card]').classList.remove('active');
            if (window.scrollY > scrollPos) {
              elem.classList.add('passed');
              elem.querySelector('[data-card]').classList.add('passed');
            }
          }
        }
      }
    }
}

function addClass(e) {
    const elemTarget = e.target.getAttribute('data-addClass');
    document.getElementById(elemTarget).classList.add('show');
    setTimeout(() => {
        document.getElementById(elemTarget).classList.add('active');
    }, 500);
}

function removeClass(e) {
    const elemTarget = e.target.getAttribute('data-removeClass');
    document.getElementById(elemTarget).classList.remove('active');
    setTimeout(() => {
        document.getElementById(elemTarget).classList.remove('show');
    }, 500);
}

function vimeoPause(e) {
    const vimeoId = e.target.getAttribute('data-video-vimeo-id');
    const elemTarget = e.target.getAttribute('data-video-vimeo');
    // const vimeo = document.getElementById(elemTarget);
    const player = new Player(elemTarget, {
        id: vimeoId,
        width: 640,
        autoplay: false,
    });

    player.pause();
}

function vimeoPlay(e) {
    const vimeoId = e.target.getAttribute('data-video-vimeo-id');
    const elemTarget = e.target.getAttribute('data-video-vimeo');
    // const vimeo = document.getElementById(elemTarget);
    // console.log('play click', vimeoId, elemTarget)
    const player = new Player(elemTarget, {
        id: vimeoId,
        width: 640,
        autoplay: true,
    });

    player.play();
}

function genTextColor() {
    const dataTextColors = document.querySelectorAll('[data-text-color]') || false;

    for (let i=0; i<dataTextColors.length; i++) {
        const textColor = dataTextColors[i].getAttribute('data-text-color') ? `#${dataTextColors[i].getAttribute('data-text-color')}` : '';
        if (textColor) {
            const dataP = dataTextColors[i].querySelectorAll('p') || false;
            for (let j=0; j < dataP.length; j++) {
                dataP[j].style.color = textColor;
            }
            const dataSpan = dataTextColors[i].querySelectorAll('span') || false;
            for (let j=0; j < dataSpan.length; j++) {
                dataSpan[j].style.color = textColor;
            }
            const dataLi = dataTextColors[i].querySelectorAll('li') || false;
            for (let j=0; j < dataLi.length; j++) {
                dataLi[j].style.color = textColor;
            }
            const dataH1 = dataTextColors[i].querySelectorAll('h1') || false;
            for (let j=0; j < dataH1.length; j++) {
                dataH1[j].style.color = textColor;
            }
            const dataH2 = dataTextColors[i].querySelectorAll('h2') || false;
            for (let j=0; j < dataH2.length; j++) {
                dataH2[j].style.color = textColor;
            }
            const dataH3 = dataTextColors[i].querySelectorAll('h3') || false;
            for (let j=0; j < dataH3.length; j++) {
                dataH3[j].style.color = textColor;
            }
            const dataSVG = dataTextColors[i].querySelectorAll('svg') || false;
            for (let j=0; j < dataSVG.length; j++) {
                dataSVG[j].style.fill = textColor;
            }
            const dataSvgPath = dataTextColors[i].querySelectorAll('svg path') || false;
            for (let j=0; j < dataSvgPath.length; j++) {
                dataSvgPath[j].style.fill = textColor;
            }
            const dataSpanH1 = document.querySelectorAll('[data-text-color] h1 span') || false;
            for (let j=0; j < dataSpanH1.length; j++) {
                dataSpanH1[j].style.color = '';
            }
            const dataSpanH2 = document.querySelectorAll('[data-text-color] h2 span') || false;
            for (let j=0; j < dataSpanH2.length; j++) {
                dataSpanH2[j].style.color = '';
            }
            const dataSpanH3 = document.querySelectorAll('[data-text-color] h3 span') || false;
            for (let j=0; j < dataSpanH3.length; j++) {
                dataSpanH3[j].style.color = '';
            }
        }
    }
}

function SlickGalleryHeight() {
    let findImgMaxHeight = 0;
    const slickSlides = document.querySelectorAll('[data-slides="gallery"] .slick-slider');
    for (let i=0; i<slickSlides.length; i++) {
        const slickImages = slickSlides[i].querySelectorAll('.img.background');
        for (let j=0; j<slickImages.length; j++) {
            if (findImgMaxHeight < slickImages[j].clientHeight) {
                findImgMaxHeight = slickImages[j].clientHeight;
            }
        }
        slickSlides[i].style.height = `${findImgMaxHeight}px`;
        slickSlides[i].querySelector('.slick-list').style.height = `${findImgMaxHeight}px`;
        slickSlides[i].querySelector('.slick-track').style.height = `${findImgMaxHeight}px`;
        slickSlides[i].querySelector('.image-container').style.height = `${findImgMaxHeight}px`;
        findImgMaxHeight = 0;
    }
    
}

function gentHTML(text) {
    return text = text.replace(/\n/gi, '<br />');
}

function ButtonLoadMore(e) {
    const classTarget = e.target.getAttribute('data-loadmore');
    const elemLoadmores = document.querySelectorAll(`.${classTarget}`);
    let cntElems = 0;
    for (let i=0; i<elemLoadmores.length; i++) {
        if (elemLoadmores[i].classList.contains('hide') === true) {
            elemLoadmores[i].classList.remove('hide');
            if (i >= (elemLoadmores.length - 1)) {
                e.target.remove();
            }
            if (cntElems>3) {
                break;
            }
            cntElems++;
        }
    }
}

function ButtonRadio(e) {
    const classTarget = e.target.getAttribute('data-target');
    const classRadio = e.target.getAttribute('data-radio');
    const elemButton = document.querySelectorAll(`.btn-radio-${classRadio}`);
    for (let i=0; i<elemButton.length; i++) {
        elemButton[i].classList.remove('active');
    }
    const elemcontents = document.querySelectorAll(`.bx-radio-${classRadio}`);
    for (let i=0; i<elemcontents.length; i++) {
        elemcontents[i].classList.add('hide');
        elemcontents[i].classList.remove('show');
    }
    e.target.classList.add('active');
    document.querySelector(`.${classTarget}`).classList.remove('hide');
    setTimeout(() => {
        document.querySelector(`.${classTarget}`).classList.add('show');
    }, 500);
}
export {
  trackRV,
  infoStorage,
  infoCookie,
  getStorage,
  getCookie,
  setStorage,
  setCookie,
  removeStorage,
  removeCookie,
  validateEmail,
  validateStrongPassword,
  validatePhoneNumber,
  isNumeric,
  isObject,
  formatPrice,
  dateOrdinal,
  getQuerystringValue,
  readTime,
  ucFirst,
  getUriFromURL,
  fetchTitle,
  dissectTitle,
  useWindowDimensions,
  useScript,
  decodeUnicode,
  rewriteShippingOptionLabel,
  relocate,
  dataLayerPush,
  radioClass,
  scrollToSection,
  scrollAnimation,
  addClass,
  removeClass,
  vimeoPause,
  vimeoPlay,
  genTextColor,
  checkScrollDirection,
  SlickGalleryHeight,
  getWindowDimensions,
  gentHTML,
  ButtonLoadMore,
  ButtonRadio,
};
