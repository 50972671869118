import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const BlogListsContext = createContext();

export const BlogListsProvider = ({ children }) => {
    const [events, setEvents] = useState([]);
    
    const contentfulPosts = useStaticQuery(graphql`
  query ContentfulCategoryLists {
    allContentfulBlogPostCategory {
      edges {
        node {
          id
          name
          slug
          blogItems {
            id
            slug
            blogTitle
            blogContent {
              raw
            }
            blogFeaturedImage {
              file {
                url
              }
            }
            updatedAt
            publishDate
          }
        }
      }
    }
  }
`);

// console.log(contentfulPosts);

const fetchPostsEvents = useCallback(() => {
  if (contentfulPosts && contentfulPosts.allContentfulBlogPostCategory.edges.length > 0) {
    setEvents(
      contentfulPosts.allContentfulBlogPostCategory.edges.map((edge) => {
        return edge.node; 
      })
    );
  }
}, [contentfulPosts]);

    useEffect(() => {
      let isFetched = true;
      fetchPostsEvents();
        return () => {
          isFetched = false;
        }
    }, [fetchPostsEvents]);

    return (
        <BlogListsContext.Provider value={{
            events
        }}>{children}</BlogListsContext.Provider>
    );
};

export default BlogListsContext;
